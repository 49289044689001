










































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { materialApi } from '@/services/material-service';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';

@Component({
    components: {

    }
})
export default class openDialog extends Vue {
    public list: any[] = [];
    public applications: any[] = [];
    public audioDuration: number = 0;
    public fileList: any[] = [];
    public actionUrl: any = '';
    public formData: any = {
        resource: '本地文件',
        audiosrc: '',
    };
    public formLabelWidth: any = '140px';
    public sourceArr: any[] = [
        { id: 0, name: '本地文件' },
        { id: 1, name: '外部链接' }
    ];
    public rules: any = {
        audioname: [
            { required: true, message: '请输入标题', trigger: ['blur', 'change'] }],
        channelId: [
            { required: true, message: '请选择', trigger: ['blur', 'change'] }],
        resource: [
            { required: true, message: '请选择来源', trigger: ['blur', 'change'] }],
    }
    @Inject(materialApi) private materialApi!: materialApi;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    public created(): void {
        // this.actionUrl = serverconfig.proxy["/api/application"].target + 'api/application/media/upload';
        this.actionUrl = document.location.origin + '/api/application/media/upload';
        // this.actionUrl = "http://localhost:8080/api/application/media/upload";
        this.applicationsList();
    }
    //获取专栏
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res;
        if (this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
            this.formData.acceptChannelId = this.applications[0].id;
        }
        // this.formData.channelId =  this.applications[0].id;
    }
    public beforeAvatarUpload(files: { type: string; size: number; name?: string; }) {
        // 文件类型进行判断
        // const isAudio = files.type === "audio/amr";
        this.getTimes(files);
        // 限制上传文件大小 2M
        const isLt2M = files.size / 1024 / 1024 < 2;
        var isAudio = this.getfileName(files.name);
        const isTime60S = this.audioDuration <= 20 ? true : '';
        // 获取时长
        console.log('files', files)
        if (!isAudio) {
            this.$message.error("上传文件格式错误!");
            this.fileList = [];
            this.audioDuration = 0;
            return false;
        } else {
            if (!isLt2M) {
                this.$message.error("上传文件大小不能超过 2MB!");
                this.fileList = [];
                this.audioDuration = 0;
                return false;
            } else {
                if (!isTime60S) {
                    this.$message.error("上传文件时长不能超过20秒!");
                    this.fileList = [];
                    this.audioDuration = 0;
                    return false;
                }
            }
        }
        this.audioDuration = 0;
        return isAudio && isLt2M && isTime60S
    }
    public async getTimes(file: any) {
        var content = file;
        this.audioDuration = 0;
        //获取录音时长
        var url = URL.createObjectURL(content);
        //经测试，发现audio也可获取视频的时长
        var audioElement: HTMLAudioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", (_event) => {
            this.audioDuration = audioElement.duration;
        });
    }
    public async uploadClick(formName: any): Promise<void> {
        const addRef = (this.$refs.formData as any);
        addRef.validate(async (valid: any) => {
            if (valid) {
                if (this.formData.resource == '本地文件') {
                    console.log('fileList', this.formData.audiosrc)
                    var data_ = {
                        'agentId': this.formData.channelId,
                        'audioTitle': this.formData.audioname,
                        'type': '3',
                        'url': this.formData.audiosrc
                    };
                    // 本地文件上传
                    if (this.formData.audiosrc && this.formData.audiosrc != null && this.formData.audiosrc != undefined) {
                        const res = await this.materialApi.savePic(data_);
                        this.$message({
                            message: '上传成功',
                            type: 'success'
                        });
                        this.getChild();
                    } else {
                        this.$message.error("请上传音频!");
                    }
                } else {
                    // 如果是手动链接
                    if (this.formData.inteweb) {
                        this.changeUrl(this.formData.inteweb)
                    } else {
                        this.$message.error("请输入链接!");
                    }
                }
            }
        })

    }
    public async handleRemove(file: any, fileList: any) {
        console.log(file, fileList);
        this.formData.audiosrc = '';
    }
    public async handlePreview(file: any) {
        console.log(file);
    }
    public async successUpload(response: any, file: any, fileList: any) {
        console.log('178', response)
        console.log(file)
        console.log(fileList)
        if (response.body.length > 0) {
            this.formData.audiosrc = response.body[0];
        } else {
            this.$message('上传失败，请重新上传！');

        }
    }
    // 手动输入链接转换
    public async changeUrl(url: any) {
        var data_ = [url]
        const res = await this.materialApi.uploadUrlPic(data_);
        if (res.length > 0) {
            this.formData.inteweb = res[0];
            var dataJson = {
                'agentId': this.formData.channelId,
                'audioTitle': this.formData.audioname,
                'type': '3',
                'url': this.formData.inteweb
            };
            const result = await this.materialApi.savePic(dataJson);
            this.getChild();
            this.$message({
                message: '上传成功',
                type: 'success'
            });

        } else {
            this.$message('输入链接有误，请检查链接！');
        }
    }
    // 去后缀名 验证是否正确
    public getfileName(name: any) {
        // var suffix = name.substring(name.lastIndexOf('.'));
        var suffix = name.substring(name.lastIndexOf('.') + 1);
        if (suffix == 'mp3' || suffix == 'amr') {
            return true
        } else {
            return false
        }

    }
    @Emit('getChild')
    public getChild(): any {
        this.formData = {
            'agentId': '',
            'audioTitle': '',
            'url': '',
            'resource': '本地文件'
        }
        return true
    }
    /**
     * -END- CALL SERVICE
     */
}
